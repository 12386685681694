import css from "./css/imageDesc.module.css"
import { FormDatatext } from "../hooks/useFormatDatatext"
const ImageDesc = ({ image, title, desc }) => {
  return (
    <div className={css.container}>
      <img src={image} alt="" className="mb-5" />
      <div className="title-h3">{FormDatatext(title)}</div>
      <div className="color-p1 desc-style mb-5">{FormDatatext(desc)}</div>
    </div>
  )
}

export default ImageDesc
