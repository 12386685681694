// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
// import { getAnalytics } from "firebase/analytics"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB6BYQrnAOc-YC3_LYxnB41jDTzOnSECIg",
  authDomain: "august-cascade-362707.firebaseapp.com",
  projectId: "august-cascade-362707",
  storageBucket: "august-cascade-362707.appspot.com",
  messagingSenderId: "442595704621",
  appId: "1:442595704621:web:aa32800bf2a5b12cc91da4",
  measurementId: "G-QTJ3TQVD84",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const db = getFirestore(app)
export default app
// const analytics = getAnalytics(app)
