import React, { useEffect, useState } from "react"

import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import styles from "../../css/UI.module.css"
// import backbtn from "../../image/button/pre_btn.png"
import { createUser } from "../../firebaseConf"
import Profile from "../Profile/Profile"
import { MuiTextfield } from "../InputField"

const SignUp = () => {
  const [idCard, setIdCard] = useState("")
  const [password, setPassword] = useState("")
  const [displayName, setDisplayName] = useState("")
  const [loanNo, setLoanNo] = useState("")
  const [error, setError] = useState("")
  const [msg, setMsg] = useState("")

  const handleCreateUser = async () => {
    const userData = { idCard, password, displayName, loanNo }
    console.log(userData)
    const rs = await createUser(userData)

    setIdCard("")
    setPassword("")
    setDisplayName("")
    setLoanNo("")
    setError("")
    setMsg("")

    if (rs.error !== "") setError(rs.error)
    if (rs.result !== "") setMsg(rs.result)
  }

  return (
    <div className={`${styles.container} main_tone`}>
      <Profile back_url={"/admindashboard"} logoLink="/admindashboard" />
      <div className="input_container">
        <h2>用戶註冊</h2>
        <MuiTextfield
          id="displayName"
          onChange={(e) => setDisplayName(e.target.value)}
          placeholder="請輸入名稱"
          label="名稱"
          value={displayName}
          type="text"
          className={styles.input_field}
        />
        <MuiTextfield
          id="idCard"
          onChange={(e) => setIdCard(e.target.value)}
          placeholder="請輸入身份證號碼"
          label="身份證號碼"
          value={idCard}
          type="text"
          className={styles.input_field}
        />
        <MuiTextfield
          id="password"
          onChange={(e) => setPassword(e.target.value)}
          placeholder="請輸入密碼"
          label="密碼"
          value={password}
          type="password"
          className={styles.input_field}
        />
        <MuiTextfield
          id="loanNo"
          onChange={(e) => setLoanNo(e.target.value)}
          placeholder="請輸入貸款號碼"
          label="貸款號碼"
          value={loanNo}
          type="text"
          className={styles.input_field}
        />

        <Button
          variant="contained"
          className={styles.functional_button}
          onClick={() => {
            handleCreateUser()
          }}
        >
          註冊
        </Button>
        {error !== "" && <p className={styles.error_msg}> {error} </p>}
        {msg !== "" && <p className={styles.success_msg}> {msg} </p>}
      </div>
    </div>
  )
}
export default SignUp
