import "./App.css"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"

import Home from "./all_pages/Home"
import PrivateLoan from "./all_pages/PrivateLoan"
import CollateralLoan from "./all_pages/CollateralLoan"
import Tu from "./all_pages/Tu"
import Blog from "./all_pages/Blog"
import CreditCard from "./all_pages/CreditCard"
import ErrorPage from "./all_pages/ErrorPage"
import Mortgage from "./all_pages/Mortgage"
import Profile from "./all_pages/Profile"
import TopMenu from "./compoients/TopMenu"
import "bootstrap/dist/css/bootstrap.min.css"
import Footer from "./compoients/Footer"
import AboutUs from "./all_pages/AboutUs"
import QA from "./all_pages/QA"
import useFetch from "./hooks/useFetch"
import { ENV } from "./config"
import Apply from "./all_pages/Apply"
import Thankyou from "./all_pages/Thankyou"
import BlogPostPage from "./compoients/BlogPostPage"

//signature
import Login from "./signature/Login"
import SignUp from "./signature/components/SignUp/SignUp"
import AdminDashBoard from "./signature/components/AdminDashboard/AdminDashBoard"
import ClientsList from "./signature/components/ClientsList/ClientsList"
import ClientDetail from "./signature/components/ClientDetail/ClientDetail"
import UserDashboard from "./signature/components/UserDashboard/UserDashboard"
import DocSign from "./signature/components/DocSign/DocSign"
import ClientUnsign from "./signature/components/ClientSigned/ClientSigned"
import ChangePassowrd from "./signature/components/ChangePassword/ChangePassword"
import { AuthContextProvider } from "./AuthContext"
import EditContract from "./signature/components/EditContract/EditContract"
import AdminLogin from "./signature/AdminLogin"
import FloatBtnList from "./compoients/FloatBtnList"
import PageHead from "./compoients/PageHead"

//end signature

function App() {
  const { data } = useFetch(ENV + "/api/v1/content")

  const getMetaData = (dataItem, label) => {
    let metaData
    if (Array.isArray(dataItem)) {
      metaData = dataItem.find((item) => item.label === label)
    } else if (dataItem && dataItem.label === label) {
      metaData = dataItem
    }

    return {
      title: metaData?.metaTitle,
      description: metaData?.metaDescription,
      keywords: metaData?.metaKeywords,
    }
  }

  const homeMetaData = getMetaData(data?.home, "home")
  const applyMetaData = getMetaData(data?.apply, "apply")
  const thankyouMetaData = getMetaData(data?.thankyou, "thankyou")
  const privateloanMetaData = getMetaData(data?.privateloan, "privateloan")
  const collateralLoanMetaData = getMetaData(
    data?.collateralLoan,
    "collateralLoan"
  )
  const creditcardMetaData = getMetaData(data?.creditcard, "creditcard")
  const mortgageMetaData = getMetaData(data?.mortgage, "mortgage")

  const blogMetaData = getMetaData(data?.blog, "blog")
  const aboutUsMetaData = getMetaData(data?.aboutus, "aboutus")
  const qaMetaData = getMetaData(data?.qa, "qa")
  const loginMetaData = getMetaData(data?.login, "login")

  return (
    <Router>
      <AuthContextProvider>
        <div className="app-container">
          <TopMenu content={data} />
          <FloatBtnList data={data} />
          <Routes>
            <Route
              exact
              path="/"
              element={
                <PageHead
                  title={homeMetaData.title}
                  description={homeMetaData.description}
                  keywords={homeMetaData.keywords}
                >
                  <Home />
                </PageHead>
              }
            />
            <Route
              path="/apply"
              element={
                <PageHead
                  title={applyMetaData.title}
                  description={applyMetaData.description}
                  keywords={applyMetaData.keywords}
                >
                  <Apply />
                </PageHead>
              }
            />
            <Route
              path="/thankyou"
              element={
                <PageHead
                  title={thankyouMetaData.title}
                  description={thankyouMetaData.description}
                  keywords={thankyouMetaData.keywords}
                >
                  <Thankyou />
                </PageHead>
              }
            />
            <Route
              path="/blog"
              element={
                <PageHead
                  title={blogMetaData.title}
                  description={blogMetaData.description}
                  keywords={blogMetaData.keywords}
                >
                  <Blog />
                </PageHead>
              }
            />
            <Route
              path="/blog/:postId"
              element={
                <PageHead
                  title={blogMetaData.title}
                  description={blogMetaData.description}
                  keywords={blogMetaData.keywords}
                >
                  <BlogPostPage />
                </PageHead>
              }
            />
            <Route
              path="/privateloan"
              element={
                <PageHead
                  title={privateloanMetaData.title}
                  description={privateloanMetaData.description}
                  keywords={privateloanMetaData.keywords}
                >
                  <PrivateLoan />
                </PageHead>
              }
            />
            <Route
              path="/collateralLoan"
              element={
                <PageHead
                  title={collateralLoanMetaData.title}
                  description={collateralLoanMetaData.description}
                  keywords={collateralLoanMetaData.keywords}
                >
                  <CollateralLoan />
                </PageHead>
              }
            />
            <Route
              path="/creditcard"
              element={
                <PageHead
                  title={creditcardMetaData.title}
                  description={creditcardMetaData.description}
                  keywords={creditcardMetaData.keywords}
                >
                  <CreditCard />
                </PageHead>
              }
            />
            <Route
              path="/mortgage"
              element={
                <PageHead
                  title={mortgageMetaData.title}
                  description={mortgageMetaData.description}
                  keywords={mortgageMetaData.keywords}
                >
                  <Mortgage />
                </PageHead>
              }
            />
            <Route
              path="/tu"
              element={
                <PageHead
                  title={aboutUsMetaData.title}
                  description={aboutUsMetaData.description}
                  keywords={aboutUsMetaData.keywords}
                >
                  <Tu />
                </PageHead>
              }
            />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route
              path="/qa"
              element={
                <PageHead
                  title={qaMetaData.title}
                  description={qaMetaData.description}
                  keywords={qaMetaData.keywords}
                >
                  <QA />
                </PageHead>
              }
            />
            <Route path="/profile/:username" element={<Profile />} />
            <Route path="*" element={<ErrorPage />} />

            {/*signature*/}

            <Route exact path="/adminLogin" element={<AdminLogin />} />

            <Route
              exact
              path="/login"
              element={
                <PageHead
                  title={loginMetaData.title}
                  description={loginMetaData.description}
                  keywords={loginMetaData.keywords}
                >
                  <Login />
                </PageHead>
              }
            />

            <Route exact path="/signup" element={<SignUp />} />

            <Route exact path="/admindashboard" element={<AdminDashBoard />} />

            <Route exact path="/clientslist" element={<ClientsList />} />

            <Route exact path="/clientdetail/:id" element={<ClientDetail />} />

            <Route exact path="/clientsigned/:id" element={<ClientUnsign />} />

            <Route
              exact
              path="/changepassword/:id"
              element={<ChangePassowrd />}
            />

            <Route
              exact
              path="/userdashboard/:id"
              element={<UserDashboard />}
            />

            <Route
              exact
              // path="/docsign/:filename/:id/:loanNo/:Borrower"
              path="/docsign/:filename/:id"
              element={<DocSign />}
            />
            <Route exact path="/editContract/:id" element={<EditContract />} />

            {/*end signature*/}
          </Routes>
          <Footer data={data} />
        </div>
      </AuthContextProvider>
    </Router>
  )
}

export default App
