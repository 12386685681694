import css from "./css/calculator.module.css"

import { useState, useEffect } from "react"
import CalculatorSlider from "./CalculatorSlider"
import money_icon from "../images/home/money.png"
import monthlybill_icon from "../images/home/Monthlybill.png"
import period_icon from "../images/home/period.png"
import { ApplyBtn } from "./Btn"
import { useNavigate } from "react-router-dom"

const Calculator = () => {
  const [displayAmount, setDisplayAmount] = useState(0)
  const [principal, setPrincipal] = useState(50000)
  const [period, setPeriod] = useState(12)
  const [monthlyPay, setMonthlyPay] = useState(0)

  const navigate = useNavigate()

  const HandleAmount = (value) => {
    setPrincipal(value)
    // FormatMoney(value)
  }

  // const FormatMoney = (value) => {
  //   setDisplayAmount(value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))
  // }

  useEffect(() => {
    let P = principal
    let t = period
    let i = 0.06 / 12
    let result = 0
    if (t !== 0) {
      result = P * ((i * (1 + i) ** t) / ((1 + i) ** t - 1))

      if (P === 0 || t === 0 || P === "" || t === "") {
        setMonthlyPay("")
      } else {
        setMonthlyPay(Math.round(result))
      }
    }
  }, [principal, period, monthlyPay])

  return (
    <div className={css.container}>
      <div className={css.box_container}>
        <div className={css.row_container}>
          <div className={css.item_title}>
            <img src={money_icon} alt="money" />
            <span>貸款金額</span>
          </div>
          <div className={css.money_input}>
            <span>HK $</span>
            <input
              type="text"
              value={principal === 0 ? "" : principal}
              style={{ color: "#f5881e" }}
              onChange={(e) => {
                setPrincipal(e.target.value)
              }}
            />
          </div>
        </div>

        <CalculatorSlider
          max_value={500000}
          step_value={1000}
          setChangedValue={HandleAmount}
          inputChanged={principal}
          sliderColor={"#ff8106"}
        />

        <div className={css.row_container}>
          <div className={css.item_title}>
            <img src={monthlybill_icon} alt="monthlybill" />
            <span>供款期數</span>
          </div>
          <div className={css.money_input}>
            <span className="ms-2">Months</span>
            <input
              type="text"
              value={period === 0 ? "" : period}
              style={{ color: "#992ad9" }}
              onChange={(e) => {
                setPeriod(e.target.value)
              }}
            />
          </div>
        </div>
        <CalculatorSlider
          max_value={120}
          step_value={12}
          setChangedValue={setPeriod}
          inputChanged={period}
          sliderColor={"#975dff"}
        />
        <div className={css.row_container}>
          <span>每期還款額：</span>
          <div>
            <span style={{ color: "#989898" }}>HK$</span>&nbsp;
            <span style={{ color: "#f5881e" }}>{monthlyPay}</span>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <ApplyBtn handle={() => navigate("/apply")} />
        </div>
      </div>
    </div>
  )
}

export default Calculator
