import "../../css/style.css"
import styles from "../../css/UI.module.css"
import Profile from "../Profile/Profile"
import Button from "@mui/material/Button"
import { useNavigate } from "react-router"
import "./adminDashboard.css"
import { useEffect } from "react"



const AdminDashBoard = () => {
  let navigate = useNavigate()  
  useEffect(() => {
    const isLogin = localStorage.getItem("isLogin");  
    if (isLogin !== "true") {      
      navigate("/login")
    }
  }, []);
  

  return (
    <div className={`${styles.container} main_tone`}>
      <Profile back_url={""} logoLink="/admindashboard" />
      <div className="input_container">
        <br />
        <h2>管理員介面</h2>
        <div className="admin-control-row">
          <h2>註冊客人戶口</h2>
          <Button
            variant="contained"
            className={styles.list_button}
            onClick={() => {
              navigate("/signup")
            }}
          >
            註冊
          </Button>
        </div>
        <hr className={styles.divider}></hr>
        <div className="admin-control-row">
          <h2>客戶資料</h2>
          <Button
            variant="contained"
            className={styles.list_button}
            onClick={() => {
              navigate("/clientslist")
            }}
          >
            進入
          </Button>
        </div>
        <hr className={styles.divider}></hr>
      </div>
    </div>
  )
}

export default AdminDashBoard
