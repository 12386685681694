import Box from "@mui/material/Box"
import Slider from "@mui/material/Slider"

const CalculatorSlider = ({
  max_value,
  step_value,
  setChangedValue,
  inputChanged,
  sliderColor,
}) => {
  return (
    <Box className="d-flex justify-content-center">
      <Slider
        defaultValue={0}
        aria-label="Default"
        valueLabelDisplay="auto"
        max={max_value}
        sx={{
          color: sliderColor,
          height: "7px",
          "& .MuiSlider-thumb": {
            width: 24,
            height: 24,
            backgroundColor: "#fff",
            "&:before": {
              boxShadow: "0 4px 8px rgba(0,0,0,0.4)",
            },
          },
          "& .MuiSlider-rail": {
            color: "#a9a9a9",
          },
        }}
        step={step_value}
        value={parseInt(inputChanged)}
        onChange={(e) => {
          setChangedValue(e.target.value)
        }}
      />
    </Box>
  )
}

export default CalculatorSlider
