import { useNavigate, useParams } from "react-router-dom"

const Profile = () => {
  let navigate = useNavigate()
  let { username } = useParams()
  return (
    <>
      profile
      {username}
      <button
        onClick={() => {
          navigate("/")
        }}
      >
        change to blog page
      </button>
    </>
  )
}

export default Profile
