import React, { useState } from "react"
import "./createContract.css"

function CreateContract() {
  const [loanInformation, setLoanInformation] = useState({
    loanType: "",
    applicationNo: "",
    applicationType: "",
    loanAmount: 0,
    loanTenor: 0,
    agent: "",
    loanDate: null,
    purpose: "",
  })

  const [personalInformation, setPersonalInformation] = useState({
    name: "",
    gender: "",
    id: "",
    birthDate: null,
    age: 0,
    address: "",
    flatType: "",
    mailAddress: "",
    homeNumber: 0,
    emailAddress: "",
    liveYear: 0,
    mobileNumber: 0,
    liveWith: "",
    maritalStatus: "",
  })

  const [employmentInformation, setEmploymentInformation] = useState({
    companyName: "",
    officeAddress: "",
    officeNumber: 0,
    industry: "",
    directLine: 0,
    position: "",
    employmentType: "",
    yearServiced: 0,
    income: 0,
  })

  const [propertyInformation, setPropertyInformation] = useState({
    address: "",
    saleableArea: 0,
    grossFloorArea: 0,
    yearCompleted: 0,
    propertyType: "",
    purchasedDate: null,
    price: 0,
    usePurpose: "",
    mortgages: [],
  })

  const [creditReferences, setCreditReferences] = useState([
    {
      name: "",
      loanAmount: 0,
      instalment: 0,
      payablePeriod: 0,
      payableAmount: 0,
    },
  ])

  const [thirdPartyInformation, setThirdPartyInformation] = useState({
    hasThirdParty: false,
    name: "",
    address: "",
    relationship: "",
  })

  const [documentReceived, setDocumentReceived] = useState({
    hasLoanAgreement: false,
    hasLoanFormCopy: false,
    hasBorrowingNote: false,
    hasMoneyLenderOrdinanceCopy: false,
    hasPersonalDataOrdinanceCopy: false,
    hasThirdPartyAgreementCopy: false,
    hasExplanationNoteCopy: false,
    hasMemoCopy: false,
    hasLoanAgreementAffordCopy: false,
    hasRepaymentScheduleCopy: false,
    hasReceiptAcknowledgementCopy: false,
  })

  const [signature, setSignature] = useState({
    signature: null,
    name: "",
  })

  const [repayments, setRepayments] = useState([
    {
      dueDate: null,
      interestRate: 0,
      principal: 0,
      interest: 0,
      repayAmount: 0,
      balance: 0,
    },
  ])

  const [suretyList, setSuretyList] = useState([
    {
      suretyName: "",
      suretyAddr: "",
    },
  ])

  const [repaymentDetails, setRepaymentDetails] = useState({
    interestRate: 0,
    firstRepaymentDate: null,
    repaymentCycle: 0,
    extendedInterest: 0,
    repaymentDate: "",
    instalment: 0,
    totalRepaymentAmount: 0,
    firstRepaymentAmount: 0,
    totalInterest: 0,
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    const formData = {
      loanInformation: loanInformation,
      personalInformation: personalInformation,
      employmentInformation: employmentInformation,
      propertyInformation: propertyInformation,
      creditReferences: creditReferences,
      thirdPartyInformation: thirdPartyInformation,
      documentReceived: documentReceived,
      signature: signature,
      repayments: repayments,
      suretyList: suretyList,
      repaymentDetails: repaymentDetails,
    }
    console.log(formData)
    fetch("http://34.81.55.244:443/contract/generateContract", {
      // fetch("http://localhost:8080/generateContract", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
      responseType: "blob", // to tell fetch to get response as Blob
    })
      .then((res) => {
        if (res.ok) {
          return res.blob() // this returns a Blob
        } else {
          throw new Error("Network response was not ok")
        }
      })
      .then((blob) => {
        // Create an object URL for the blob
        const url = window.URL.createObjectURL(blob)
        // Create a new anchor element
        const a = document.createElement("a")
        a.style.display = "none"
        a.href = url
        // the filename you want
        const userID = localStorage.getItem("userID")
        a.download = userID + new Date() + ".pdf"
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        console.log("finish!!!")
      })
      .catch((err) => {
        console.log(err.message)
      })
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-container">
        <div className="form-group">
          <h3>貸款資料</h3>
          <label>
            信貸類別:
            <input
              type="text"
              name="loanType"
              value={loanInformation.loanType}
              onChange={(e) =>
                setLoanInformation({
                  ...loanInformation,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </label>
          <label>
            申請編號:
            <input
              type="text"
              name="applicationNo"
              value={loanInformation.applicationNo}
              onChange={(e) =>
                setLoanInformation({
                  ...loanInformation,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </label>
          <label>
            申請人類別:
            <input
              type="text"
              name="applicationType"
              value={loanInformation.applicationType}
              onChange={(e) =>
                setLoanInformation({
                  ...loanInformation,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </label>
          <label>
            貸款額:
            <input
              type="number"
              name="loanAmount"
              value={loanInformation.loanAmount}
              onChange={(e) =>
                setLoanInformation({
                  ...loanInformation,
                  [e.target.name]: parseInt(e.target.value),
                })
              }
            />
          </label>
          <label>
            還款期:
            <input
              type="number"
              name="loanTenor"
              value={loanInformation.loanTenor}
              onChange={(e) =>
                setLoanInformation({
                  ...loanInformation,
                  [e.target.name]: parseInt(e.target.value),
                })
              }
            />
          </label>
          <label>
            代理公司:
            <input
              type="text"
              name="agent"
              value={loanInformation.agent}
              onChange={(e) =>
                setLoanInformation({
                  ...loanInformation,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </label>
          <label>
            申請日期:
            <input
              type="date"
              name="loanDate"
              value={loanInformation.loanDate}
              onChange={(e) =>
                setLoanInformation({
                  ...loanInformation,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </label>
          <label>
            貸款用途:
            <input
              type="text"
              name="purpose"
              value={loanInformation.purpose}
              onChange={(e) =>
                setLoanInformation({
                  ...loanInformation,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </label>
        </div>

        <div className="form-group">
          <h3>個人資料</h3>
          <label>
            名稱:
            <input
              type="text"
              name="name"
              value={personalInformation.name}
              onChange={(e) =>
                setPersonalInformation({
                  ...personalInformation,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </label>
          <label>
            性別:
            <select
              name="gender"
              value={personalInformation.gender}
              onChange={(e) =>
                setPersonalInformation({
                  ...personalInformation,
                  [e.target.name]: e.target.value,
                })
              }
            >
              <option value="">Select</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </label>

          <label>
            證件 / 商業登記號
            <input
              type="text"
              name="id"
              value={personalInformation.id}
              onChange={(e) =>
                setPersonalInformation({
                  ...personalInformation,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </label>
          <label>
            出生日期 / 成立日期:
            <input
              type="date"
              name="birthDate"
              value={personalInformation.birthDate}
              onChange={(e) =>
                setPersonalInformation({
                  ...personalInformation,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </label>
          <label>
            年齡 / 年期:
            <input
              type="number"
              name="age"
              value={personalInformation.age}
              onChange={(e) =>
                setPersonalInformation({
                  ...personalInformation,
                  [e.target.name]: parseInt(e.target.value),
                })
              }
            />
          </label>
          <label>
            地址:
            <textarea
              name="address"
              value={personalInformation.address}
              onChange={(e) =>
                setPersonalInformation({
                  ...personalInformation,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </label>
          <label>
            住宅類別:
            <input
              type="text"
              name="flatType"
              value={personalInformation.flatType}
              onChange={(e) =>
                setPersonalInformation({
                  ...personalInformation,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </label>
          <label>
            通訊地址:
            <textarea
              name="mailAddress"
              value={personalInformation.mailAddress}
              onChange={(e) =>
                setPersonalInformation({
                  ...personalInformation,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </label>
          <label>
            住宅電話:
            <input
              type="number"
              name="homeNumber"
              value={personalInformation.homeNumber}
              onChange={(e) =>
                setPersonalInformation({
                  ...personalInformation,
                  [e.target.name]: parseInt(e.target.value),
                })
              }
            />
          </label>
          <label>
            電郵地址:
            <input
              type="text"
              name="emailAddress"
              value={personalInformation.emailAddress}
              onChange={(e) =>
                setPersonalInformation({
                  ...personalInformation,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </label>
          <label>
            居住年期:
            <input
              type="number"
              name="liveYear"
              value={personalInformation.liveYear}
              onChange={(e) =>
                setPersonalInformation({
                  ...personalInformation,
                  [e.target.name]: parseInt(e.target.value),
                })
              }
            />
          </label>
          <label>
            手提電話:
            <input
              type="number"
              name="mobileNumber"
              value={personalInformation.mobileNumber}
              onChange={(e) =>
                setPersonalInformation({
                  ...personalInformation,
                  [e.target.name]: parseInt(e.target.value),
                })
              }
            />
          </label>
          <label>
            與誰同住:
            <input
              type="text"
              name="liveWith"
              value={personalInformation.liveWith}
              onChange={(e) =>
                setPersonalInformation({
                  ...personalInformation,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </label>
          <label>
            婚姻狀況:
            <select
              name="maritalStatus"
              value={personalInformation.maritalStatus}
              onChange={(e) =>
                setPersonalInformation({
                  ...personalInformation,
                  [e.target.name]: e.target.value,
                })
              }
            >
              <option value="">Select</option>
              <option value="Single">Single</option>
              <option value="Married">Married</option>
              <option value="Divorced">Divorced</option>
              <option value="Widowed">Widowed</option>
            </select>
          </label>
        </div>

        <div className="form-group">
          <h3>工作資料</h3>
          <label>
            公司名稱:
            <input
              type="text"
              name="companyName"
              value={employmentInformation.companyName}
              onChange={(e) =>
                setEmploymentInformation({
                  ...employmentInformation,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </label>
          <label>
            公司地址:
            <textarea
              name="officeAddress"
              value={employmentInformation.officeAddress}
              onChange={(e) =>
                setEmploymentInformation({
                  ...employmentInformation,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </label>
          <label>
            公司電話:
            <input
              type="number"
              name="officeNumber"
              value={employmentInformation.officeNumber}
              onChange={(e) =>
                setEmploymentInformation({
                  ...employmentInformation,
                  [e.target.name]: parseInt(e.target.value),
                })
              }
            />
          </label>
          <label>
            職業:
            <input
              type="text"
              name="industry"
              value={employmentInformation.industry}
              onChange={(e) =>
                setEmploymentInformation({
                  ...employmentInformation,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </label>
          <label>
            直線電話:
            <input
              type="number"
              name="directLine"
              value={employmentInformation.directLine}
              onChange={(e) =>
                setEmploymentInformation({
                  ...employmentInformation,
                  [e.target.name]: parseInt(e.target.value),
                })
              }
            />
          </label>
          <label>
            職位:
            <input
              type="text"
              name="position"
              value={employmentInformation.position}
              onChange={(e) =>
                setEmploymentInformation({
                  ...employmentInformation,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </label>
          <label>
            受騁方式:
            <input
              type="text"
              name="employmentType"
              value={employmentInformation.employmentType}
              onChange={(e) =>
                setEmploymentInformation({
                  ...employmentInformation,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </label>
          <label>
            服務年期:
            <input
              type="number"
              name="yearServiced"
              value={employmentInformation.yearServiced}
              onChange={(e) =>
                setEmploymentInformation({
                  ...employmentInformation,
                  [e.target.name]: parseInt(e.target.value),
                })
              }
            />
          </label>
          <label>
            每月收入:
            <input
              type="number"
              name="income"
              value={employmentInformation.income}
              onChange={(e) =>
                setEmploymentInformation({
                  ...employmentInformation,
                  [e.target.name]: parseInt(e.target.value),
                })
              }
            />
          </label>
        </div>

        <div className="form-group">
          <h3>物業資料</h3>
          <label>
            物業地址:
            <textarea
              name="address"
              value={propertyInformation.address}
              onChange={(e) =>
                setPropertyInformation({
                  ...propertyInformation,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </label>
          <label>
            實用面積:
            <input
              type="number"
              name="saleableArea"
              value={propertyInformation.saleableArea}
              onChange={(e) =>
                setPropertyInformation({
                  ...propertyInformation,
                  [e.target.name]: parseInt(e.target.value),
                })
              }
            />
          </label>
          <label>
            建築面積:
            <input
              type="number"
              name="grossFloorArea"
              value={propertyInformation.grossFloorArea}
              onChange={(e) =>
                setPropertyInformation({
                  ...propertyInformation,
                  [e.target.name]: parseInt(e.target.value),
                })
              }
            />
          </label>
          <label>
            落成年份:
            <input
              type="number"
              name="yearCompleted"
              value={propertyInformation.yearCompleted}
              onChange={(e) =>
                setPropertyInformation({
                  ...propertyInformation,
                  [e.target.name]: parseInt(e.target.value),
                })
              }
            />
          </label>
          <label>
            物業類別:
            <input
              type="text"
              name="propertyType"
              value={propertyInformation.propertyType}
              onChange={(e) =>
                setPropertyInformation({
                  ...propertyInformation,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </label>
          <label>
            購入日期:
            <input
              type="date"
              name="purchasedDate"
              value={propertyInformation.purchasedDate}
              onChange={(e) =>
                setPropertyInformation({
                  ...propertyInformation,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </label>
          <label>
            購入價:
            <input
              type="number"
              name="price"
              value={propertyInformation.price}
              onChange={(e) =>
                setPropertyInformation({
                  ...propertyInformation,
                  [e.target.name]: parseInt(e.target.value),
                })
              }
            />
          </label>
          <label>
            物業用途:
            <input
              type="text"
              name="usePurpose"
              value={propertyInformation.usePurpose}
              onChange={(e) =>
                setPropertyInformation({
                  ...propertyInformation,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </label>
          {/* Mortgages */}
          <div>
            <h4>按揭銀行</h4>
            {propertyInformation.mortgages.map((mortgage, index) => (
              <div key={index}>
                <label>
                  銀行:
                  <input
                    type="text"
                    value={mortgage.bank}
                    onChange={(e) => {
                      const mortgages = [...propertyInformation.mortgages]
                      mortgages[index].bank = e.target.value
                      setPropertyInformation({
                        ...propertyInformation,
                        mortgages: mortgages,
                      })
                    }}
                  />
                </label>
                <label>
                  按揭結欠:
                  <input
                    type="number"
                    value={mortgage.mortgageAmount}
                    onChange={(e) => {
                      const mortgages = [...propertyInformation.mortgages]
                      mortgages[index].mortgageAmount = parseInt(e.target.value)
                      setPropertyInformation({
                        ...propertyInformation,
                        mortgages: mortgages,
                      })
                    }}
                  />
                </label>
                <button
                  type="button"
                  onClick={() => {
                    const mortgages = [...propertyInformation.mortgages]
                    mortgages.splice(index, 1)
                    setPropertyInformation({
                      ...propertyInformation,
                      mortgages: mortgages,
                    })
                  }}
                >
                  刪除
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => {
                const mortgages = [...propertyInformation.mortgages]
                mortgages.push({ bank: "", mortgageAmount: 0 })
                setPropertyInformation({
                  ...propertyInformation,
                  mortgages: mortgages,
                })
              }}
            >
              新增按揭
            </button>
          </div>
        </div>

        <div className="form-group">
          <h3>信貸紀錄</h3>
          {creditReferences.map((creditRef, index) => (
            <div key={index}>
              <label>
                貸方名稱:
                <input
                  type="text"
                  value={creditRef.name}
                  onChange={(e) => {
                    const creditRefs = [...creditReferences]
                    creditRefs[index].name = e.target.value
                    setCreditReferences(creditRefs)
                  }}
                />
              </label>
              <label>
                貸款金額:
                <input
                  type="number"
                  value={creditRef.loanAmount}
                  onChange={(e) => {
                    const creditRefs = [...creditReferences]
                    creditRefs[index].loanAmount = parseInt(e.target.value)
                    setCreditReferences(creditRefs)
                  }}
                />
              </label>
              <label>
                每期還款:
                <input
                  type="number"
                  value={creditRef.instalment}
                  onChange={(e) => {
                    const creditRefs = [...creditReferences]
                    creditRefs[index].instalment = parseInt(e.target.value)
                    setCreditReferences(creditRefs)
                  }}
                />
              </label>
              <label>
                尚欠期數:
                <input
                  type="number"
                  value={creditRef.payablePeriod}
                  onChange={(e) => {
                    const creditRefs = [...creditReferences]
                    creditRefs[index].payablePeriod = parseInt(e.target.value)
                    setCreditReferences(creditRefs)
                  }}
                />
              </label>
              <label>
                尚欠金額:
                <input
                  type="number"
                  value={creditRef.payableAmount}
                  onChange={(e) => {
                    const creditRefs = [...creditReferences]
                    creditRefs[index].payableAmount = parseInt(e.target.value)
                    setCreditReferences(creditRefs)
                  }}
                />
              </label>
              <button
                type="button"
                onClick={() => {
                  const creditRefs = [...creditReferences]
                  creditRefs.splice(index, 1)
                  setCreditReferences(creditRefs)
                }}
              >
                刪除
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() => {
              const creditRefs = [...creditReferences]
              creditRefs.push({
                name: "",
                loanAmount: 0,
                instalment: 0,
                payablePeriod: 0,
                payableAmount: 0,
              })
              setCreditReferences(creditRefs)
            }}
          >
            新增信貸紀錄
          </button>
        </div>

        <div className="form-group">
          <h3>第三方資料</h3>
          <label>
            有第三方資料:
            <input
              type="checkbox"
              name="hasThirdParty"
              checked={thirdPartyInformation.hasThirdParty}
              onChange={(e) =>
                setThirdPartyInformation({
                  ...thirdPartyInformation,
                  hasThirdParty: e.target.checked,
                })
              }
            />
          </label>
          {thirdPartyInformation.hasThirdParty && (
            <div>
              <label>
                姓名/名稱:
                <input
                  type="text"
                  name="name"
                  value={thirdPartyInformation.name}
                  onChange={(e) =>
                    setThirdPartyInformation({
                      ...thirdPartyInformation,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </label>
              <label>
                地址:
                <textarea
                  name="address"
                  value={thirdPartyInformation.address}
                  onChange={(e) =>
                    setThirdPartyInformation({
                      ...thirdPartyInformation,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </label>
              <label>
                與貴公司關係:
                <input
                  type="text"
                  name="relationship"
                  value={thirdPartyInformation.relationship}
                  onChange={(e) =>
                    setThirdPartyInformation({
                      ...thirdPartyInformation,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </label>
            </div>
          )}
        </div>

        <div className="form-group">
          <h3>文件簽收證明書</h3>
          <label>
            貸款合約:
            <input
              type="checkbox"
              name="hasLoanAgreement"
              checked={documentReceived.hasLoanAgreement}
              onChange={(e) =>
                setDocumentReceived({
                  ...documentReceived,
                  hasLoanAgreement: e.target.checked,
                })
              }
            />
          </label>
          <label>
            貸款申請書副本:
            <input
              type="checkbox"
              name="hasLoanFormCopy"
              checked={documentReceived.hasLoanFormCopy}
              onChange={(e) =>
                setDocumentReceived({
                  ...documentReceived,
                  hasLoanFormCopy: e.target.checked,
                })
              }
            />
          </label>
          <label>
            擬借貸人須知:
            <input
              type="checkbox"
              name="hasBorrowingNote"
              checked={documentReceived.hasBorrowingNote}
              onChange={(e) =>
                setDocumentReceived({
                  ...documentReceived,
                  hasBorrowingNote: e.target.checked,
                })
              }
            />
          </label>
          <label>
            放債人條例副本:
            <input
              type="checkbox"
              name="hasMoneyLenderOrdinanceCopy"
              checked={documentReceived.hasMoneyLenderOrdinanceCopy}
              onChange={(e) =>
                setDocumentReceived({
                  ...documentReceived,
                  hasMoneyLenderOrdinanceCopy: e.target.checked,
                })
              }
            />
          </label>
          <label>
            個人資料(私穩)條例通知書副本:
            <input
              type="checkbox"
              name="hasPersonalDataOrdinanceCopy"
              checked={documentReceived.hasPersonalDataOrdinanceCopy}
              onChange={(e) =>
                setDocumentReceived({
                  ...documentReceived,
                  hasPersonalDataOrdinanceCopy: e.target.checked,
                })
              }
            />
          </label>
          <label>
            第三方協議確認書副本:
            <input
              type="checkbox"
              name="hasThirdPartyAgreementCopy"
              checked={documentReceived.hasThirdPartyAgreementCopy}
              onChange={(e) =>
                setDocumentReceived({
                  ...documentReceived,
                  hasThirdPartyAgreementCopy: e.target.checked,
                })
              }
            />
          </label>
          <label>
            解釋協議的備忘錄副本:
            <input
              type="checkbox"
              name="hasExplanationNoteCopy"
              checked={documentReceived.hasExplanationNoteCopy}
              onChange={(e) =>
                setDocumentReceived({
                  ...documentReceived,
                  hasExplanationNoteCopy: e.target.checked,
                })
              }
            />
          </label>
          <label>
            貸款協議備忘錄副本:
            <input
              type="checkbox"
              name="hasMemoCopy"
              checked={documentReceived.hasMemoCopy}
              onChange={(e) =>
                setDocumentReceived({
                  ...documentReceived,
                  hasMemoCopy: e.target.checked,
                })
              }
            />
          </label>
          <label>
            貸款協議負擔能力聲名副本:
            <input
              type="checkbox"
              name="hasLoanAgreementAffordCopy"
              checked={documentReceived.hasLoanAgreementAffordCopy}
              onChange={(e) =>
                setDocumentReceived({
                  ...documentReceived,
                  hasLoanAgreementAffordCopy: e.target.checked,
                })
              }
            />
          </label>
          <label>
            還款表副本:
            <input
              type="checkbox"
              name="hasRepaymentScheduleCopy"
              checked={documentReceived.hasRepaymentScheduleCopy}
              onChange={(e) =>
                setDocumentReceived({
                  ...documentReceived,
                  hasRepaymentScheduleCopy: e.target.checked,
                })
              }
            />
          </label>
          <label>
            放款確認書副本:
            <input
              type="checkbox"
              name="hasReceiptAcknowledgementCopy"
              checked={documentReceived.hasReceiptAcknowledgementCopy}
              onChange={(e) =>
                setDocumentReceived({
                  ...documentReceived,
                  hasReceiptAcknowledgementCopy: e.target.checked,
                })
              }
            />
          </label>
        </div>

        <div className="form-group">
          <h3>借款人簽署</h3>
          <label>
            簽署:
            <input
              type="text"
              name="signature"
              value={signature.signature}
              onChange={(e) =>
                setSignature({
                  ...signature,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </label>
          <label>
            姓名:
            <input
              type="text"
              name="name"
              value={signature.name}
              onChange={(e) =>
                setSignature({
                  ...signature,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </label>
        </div>

        <div className="form-group">
          <h3>還款</h3>
          {repayments.map((repayment, index) => (
            <div key={index}>
              <label>
                供款日期:
                <input
                  type="date"
                  value={repayment.dueDate}
                  onChange={(e) => {
                    const repaymentsCopy = [...repayments]
                    repaymentsCopy[index].dueDate = e.target.value
                    setRepayments(repaymentsCopy)
                  }}
                />
              </label>
              <label>
                年利率:
                <input
                  type="number"
                  value={repayment.interestRate}
                  onChange={(e) => {
                    const repaymentsCopy = [...repayments]
                    repaymentsCopy[index].interestRate = parseFloat(
                      e.target.value
                    )
                    setRepayments(repaymentsCopy)
                  }}
                />
              </label>
              <label>
                本金:
                <input
                  type="number"
                  value={repayment.principal}
                  onChange={(e) => {
                    const repaymentsCopy = [...repayments]
                    repaymentsCopy[index].principal = parseInt(e.target.value)
                    setRepayments(repaymentsCopy)
                  }}
                />
              </label>
              <label>
                利息:
                <input
                  type="number"
                  value={repayment.interest}
                  onChange={(e) => {
                    const repaymentsCopy = [...repayments]
                    repaymentsCopy[index].interest = parseInt(e.target.value)
                    setRepayments(repaymentsCopy)
                  }}
                />
              </label>
              <label>
                供款金額:
                <input
                  type="number"
                  value={repayment.repayAmount}
                  onChange={(e) => {
                    const repaymentsCopy = [...repayments]
                    repaymentsCopy[index].repayAmount = parseInt(e.target.value)
                    setRepayments(repaymentsCopy)
                  }}
                />
              </label>
              <label>
                尚欠本金:
                <input
                  type="number"
                  value={repayment.balance}
                  onChange={(e) => {
                    const repaymentsCopy = [...repayments]
                    repaymentsCopy[index].balance = parseInt(e.target.value)
                    setRepayments(repaymentsCopy)
                  }}
                />
              </label>
              <button
                type="button"
                onClick={() => {
                  const repaymentsCopy = [...repayments]
                  repaymentsCopy.splice(index, 1)
                  setRepayments(repaymentsCopy)
                }}
              >
                刪除
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() => {
              const repaymentsCopy = [...repayments]
              repaymentsCopy.push({
                dueDate: null,
                interestRate: 0,
                principal: 0,
                interest: 0,
                repayAmount: 0,
                balance: 0,
              })
              setRepayments(repaymentsCopy)
            }}
          >
            新增還款
          </button>
        </div>

        <div className="form-group">
          <h3>擔保人名單</h3>
          {suretyList.map((surety, index) => (
            <div key={index}>
              <label>
                擔保人名稱:
                <input
                  type="text"
                  value={surety.suretyName}
                  onChange={(e) => {
                    const suretyListCopy = [...suretyList]
                    suretyListCopy[index].suretyName = e.target.value
                    setSuretyList(suretyListCopy)
                  }}
                />
              </label>
              <label>
                擔保人地址:
                <textarea
                  value={surety.suretyAddr}
                  onChange={(e) => {
                    const suretyListCopy = [...suretyList]
                    suretyListCopy[index].suretyAddr = e.target.value
                    setSuretyList(suretyListCopy)
                  }}
                />
              </label>
              <button
                type="button"
                onClick={() => {
                  const suretyListCopy = [...suretyList]
                  suretyListCopy.splice(index, 1)
                  setSuretyList(suretyListCopy)
                }}
              >
                刪除
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() => {
              const suretyListCopy = [...suretyList]
              suretyListCopy.push({ suretyName: "", suretyAddr: "" })
              setSuretyList(suretyListCopy)
            }}
          >
            新增擔保人
          </button>
        </div>

        <div className="form-group">
          <h3>還款資料</h3>
          <label>
            年利率:
            <input
              type="number"
              name="interestRate"
              value={repaymentDetails.interestRate}
              onChange={(e) =>
                setRepaymentDetails({
                  ...repaymentDetails,
                  [e.target.name]: parseFloat(e.target.value),
                })
              }
            />
          </label>
          <label>
            首期還款日:
            <input
              type="date"
              name="firstRepaymentDate"
              value={repaymentDetails.firstRepaymentDate}
              onChange={(e) =>
                setRepaymentDetails({
                  ...repaymentDetails,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </label>
          <label>
            還款週期:
            <input
              type="number"
              name="repaymentCycle"
              value={repaymentDetails.repaymentCycle}
              onChange={(e) =>
                setRepaymentDetails({
                  ...repaymentDetails,
                  [e.target.name]: parseInt(e.target.value),
                })
              }
            />
          </label>
          <label>
            延期息:
            <input
              type="number"
              name="extendedInterest"
              value={repaymentDetails.extendedInterest}
              onChange={(e) =>
                setRepaymentDetails({
                  ...repaymentDetails,
                  [e.target.name]: parseInt(e.target.value),
                })
              }
            />
          </label>
          <label>
            每期還款日:
            <input
              type="text"
              name="repaymentDate"
              value={repaymentDetails.repaymentDate}
              onChange={(e) =>
                setRepaymentDetails({
                  ...repaymentDetails,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </label>
          <label>
            每月供款:
            <input
              type="number"
              name="instalment"
              value={repaymentDetails.instalment}
              onChange={(e) =>
                setRepaymentDetails({
                  ...repaymentDetails,
                  [e.target.name]: parseInt(e.target.value),
                })
              }
            />
          </label>
          <label>
            總供款額:
            <input
              type="number"
              name="totalRepaymentAmount"
              value={repaymentDetails.totalRepaymentAmount}
              onChange={(e) =>
                setRepaymentDetails({
                  ...repaymentDetails,
                  [e.target.name]: parseInt(e.target.value),
                })
              }
            />
          </label>
          <label>
            首期還款額:
            <input
              type="number"
              name="firstRepaymentAmount"
              value={repaymentDetails.firstRepaymentAmount}
              onChange={(e) =>
                setRepaymentDetails({
                  ...repaymentDetails,
                  [e.target.name]: parseInt(e.target.value),
                })
              }
            />
          </label>
          <label>
            總利息:
            <input
              type="number"
              name="totalInterest"
              value={repaymentDetails.totalInterest}
              onChange={(e) =>
                setRepaymentDetails({
                  ...repaymentDetails,
                  [e.target.name]: parseInt(e.target.value),
                })
              }
            />
          </label>
        </div>
        <button className="submit_btn" type="submit">
          提交
        </button>
      </div>
    </form>
  )
}

export default CreateContract
